<template>
    <div class="page-container" v-loading="loading">
        <div class="page-container-header">
            <div class="title">{{title || $route.name || $route.meta.name || ''}}</div>
            <div class="desc">
                <slot name="head" />
            </div>
        </div>

        <div class="page-container-body">
            <div v-if="$slots['left-content'] || $slots['left-header']" class="page-container-left">
                <div class="page-container-content" ref="page-container-left-content">
                    <div v-if="$slots['left-header']" ref="page-container-left-content-header" class="page-container-content-header">
                        <slot name="left-header"/>
                    </div>

                    
                    <div v-if="$slots['left-content']" ref="page-container-left-content-main" :style="{height:pageLeftHeight+'px',overflowY: 'overlay'}">
                        <slot name="left-content"/>
                    </div>
                </div>
            </div>

            <div v-if="$slots['header'] || $slots['default']" class="page-container-main">
                <div class="page-container-content" ref="page-container-main-content">
                    <div v-if="$slots['header']" ref="page-container-main-content-header" class="page-container-content-header">
                        <slot name="header"/>
                    </div>

                    <div v-if="$slots['default']" ref="page-container-main-content-main" :style="{height:pageMainHeight+'px',overflowY: 'overlay'}">
                        <slot />
                    </div>
                </div>
            </div>

            <div v-if="$slots['right-content'] || $slots['right-header']" class="page-container-right">
                <div class="page-container-content" ref="page-container-right-content">
                    <div v-if="$slots['right-header']" ref="page-container-right-content-header" class="page-container-content-header">
                        <slot name="right-header"/>
                    </div>
                    
                    <div v-if="$slots['right-content']" ref="page-container-right-content-main" :style="{height:pageRightHeight+'px',overflowY: 'overlay'}">
                        <slot name="right-content"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "pageMain",
    props: {
        title   : {type: String,default: ''},
        loading : {type: Boolean,default: false},//loading
    },

    data(){
        return {
            pageMainHeight:'500',
            pageLeftHeight:'500',
            pageRightHeight:'500',
        }
    },
    mounted() {
        this.$nextTick().then(()=>{
            if( (this.$slots['header'] || this.$slots['default']) && this.$refs['page-container-main-content'] && this.$refs['page-container-main-content'].offsetHeight ){
                let pageMainHeight  = this.$refs['page-container-main-content'].offsetHeight;
                if( this.$slots['header'] && this.$refs['page-container-main-content-header'] && this.$refs['page-container-main-content-header'].offsetHeight ){
                    pageMainHeight  = pageMainHeight - this.$refs['page-container-main-content-header'].offsetHeight;
                }

                this.pageMainHeight = pageMainHeight-2;
            }

            if( (this.$slots['left-content'] || this.$slots['left-header']) && this.$refs['page-container-left-content'] && this.$refs['page-container-left-content'].offsetHeight ){
                let pageLeftHeight  = this.$refs['page-container-left-content'].offsetHeight;
                if( this.$slots['left-header'] ){
                    pageLeftHeight  = pageLeftHeight - this.$refs['page-container-left-content-header'].$el.offsetHeight;
                }
                this.pageLeftHeight = pageLeftHeight-2;
            }

            if( (this.$slots['right-content'] || this.$slots['right-header']) && this.$refs['page-container-right-content'] && this.$refs['page-container-right-content'].offsetHeight ){
                let pageRightHeight  = this.$refs['page-container-right-content'].offsetHeight;
                if( this.$slots['right-header'] ){
                    pageRightHeight  = pageRightHeight - this.$refs['page-container-right-content-header'].$el.offsetHeight;
                }
                this.pageRightHeight = pageRightHeight-2;
            }
        });
    }
}
</script>
<style lang="scss" scoped>
.page-container{
    width: 100%;
    height: 100%;
    position: relative;
    .page-container-header {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 60px;
        z-index: 100;
        display:flex;
        justify-content: flex-start;
        .title {
            flex-basis: auto;
            white-space: nowrap;
            padding: 0 20px;
            font-size: 18px;
            line-height: 60px;
        }
        .desc{
            width: 100%;
            line-height:60px;
            text-align:right;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .page-container-header-search {
            width: 300px;
            margin: -18px 0 0 -150px;
            position: absolute;
            left: 50%;
            top: 50%;
        }

        .page-container-header-right {
            margin-right: -10px;
            float: right;
            margin: 12px 20px 0 0;
            position: relative;
            .right-item {
                float: left;
                margin-right: 10px;
                padding: 8px 10px;
                font-size: 13px;
                border-radius: 2px;
            }

            .right-more-item {
                cursor: pointer;
                border: 1px solid #dcdfe6;
                background-color: white;
                font-size: 13px;
                color: #777;
                padding: 0 12px;
                border-radius: 2px;
                height: 31px;
            }
        }
    }
    .page-container-body{
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        display: flex;
        justify-content: flex-start;
        
        .page-container-left{
            position: relative;
            // margin-right: 15px;
            padding-top: 60px;
            height: 100%;
            .page-container-content{
                background-color: rgb(255, 255, 255);
                border-width: 1px;
                border-style: solid;
                border-color: rgb(230, 230, 230);
                border-image: initial;
                border-radius: 2px;
                height: 100%;
            }
        }

        .page-container-right{
            position: relative;
            // margin-right: 15px;
            padding-top: 60px;
            height: 100%;
            .page-container-content{
                background-color: rgb(255, 255, 255);
                border-width: 1px;
                border-style: solid;
                border-color: rgb(230, 230, 230);
                border-image: initial;
                border-radius: 2px;
                height: 100%;
            }
        }

        .page-container-main{
            position: relative;
            width: 100%;
            min-width: 590px;

            .page-container-content{
                position: absolute;
                top: 60px;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgb(255, 255, 255);
                border-width: 1px;
                border-style: solid;
                border-color: rgb(230, 230, 230);
                border-image: initial;
                border-radius: 2px;
            }
        }
        .page-container-content-header{
            font-size: 13px;
            min-height: 50px;
            padding: 0 10px;
        }
        .page-container-content-main{
            position: relative;
        }
    }
    
}
</style>